.container {
  display: flex;
  flex-direction: row;
  flex: 1 1 0px;
  justify-content: space-between;
}
.accordion {
  border-radius: 5px;
  min-height: 3.5rem;
}

.accordionStationWrapper {
  display: flex;
  justify-content: flex-start;
  flex-grow: 1.5;
  flex-basis: 0;
  align-items: center;
  padding-left: 1rem;
}

.accordionTextPower {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  align-items: center;
  align-self: center;
}

.accordionDuration {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  justify-content: flex-start;
  align-items: center;
  align-self: center;
}

.accordionDateContainer {
  display: flex;
  flex-direction: column;
  flex-basis: 0;
  flex-grow: 1;
  align-items: center;
  align-self: center;
}

.accordionTime {
  font-weight: 400;
  font-size: 14px;
}

.accordionDate {
  margin: 0;
  font-weight: 400;
  font-size: 14px;
}

.accordionicon {
  align-self: center;
  width: 24px;
  margin-bottom: 2px;
}

.flashIcon {
  margin-left: 1rem;
  align-self: center;
  width: 18px;
  height: 18px;
  margin-bottom: 2px;
}

.ChargingIcon {
  width: 24px;
  height: 24px;
  margin-left: 0.25rem;
  margin-right: 0.5rem;
}

.accordionSummary {
  padding-left: 0 !important;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  height: 4rem;
}
.accordionStartDate {
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}

.accordionEndDate {
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}

.startDateContainer {
  display: flex;
  flex-direction: row;
  padding-bottom: 1rem;
  justify-content: space-between;
}

.endDateContainer {
  display: flex;
  flex-direction: row;
  padding-bottom: 1rem;
  justify-content: space-between;
}

.accordionDurationText {
  font-size: 14px;
  font-weight: 400;
  margin-left: 0;
  align-items: center;
  justify-content: space-between;
}

.accordionTextEnergyConsumption {
  font-size: 14px;
  font-weight: 400;
  padding-left: 0.7rem;
  margin-left: 0;
}

.leftDetailsContainer {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
}

.startText {
  font-size: 14px;
  font-weight: 600;
  padding-right: 2rem;
}

.endText {
  font-size: 14px;
  font-weight: 600;
  padding-right: 2.375rem;
}

.durationText {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  padding-right: 6.125rem;
}

.durationTime {
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}

.durationContainer {
  display: flex;
  flex-direction: row;
  padding-bottom: 1rem;
}

.rightDetailsContainer {
  display: flex;
  flex-direction: column;
}

.stationText a {
  display: flex;
  flex-direction: column;
  position: relative;
  font-weight: 600;
  font-size: 14px;
  margin-left: 0px;
  text-align: start;
}

.stationText:hover {
  text-decoration: underline;
}

.chargePointName {
  font-weight: 400;
  font-size: 14px;
  margin: 0;
  position: relative;
}

.amountContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 0.5;
  flex-basis: 0;
  align-items: center;
  align-self: center;
}

.currencyText {
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}

.amount {
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}

.accordionDetails {
  padding: 16px 16px 20px 16px;
}

.middleDetailsContainer {
  display: flex;
  flex-direction: column;
}

.connectorTypeContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 1rem;
}

.connectorIDContainer {
  display: flex;
  flex-direction: row;
  padding-bottom: 1rem;
}

.connectorTypeText {
  font-size: 14px;
  font-weight: 600;
}

.connectorIDText {
  font-size: 14px;
  font-weight: 600;
  padding-right: 3.625rem;
}

.connectorID {
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}

.reservationContainer {
  display: flex;
  flex-direction: row;
}

.reservationText {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}

.reservationID {
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}

.rightDetailsContainer {
  display: flex;
  flex-direction: column;
}

.subscriptionContainer {
  display: flex;
  flex-direction: row;
  padding-bottom: 1rem;
  justify-content: space-between;
}

.subscriptionText {
  font-size: 14px;
  font-weight: 600;
  padding-right: 2rem;
}

.subscription {
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}

.chargingLevelContainer {
  display: flex;
  flex-direction: row;
  padding-bottom: 1rem;
  justify-content: space-between;
}

.chargingLevelText {
  font-size: 14px;
  font-weight: 600;
}

.tariffContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.tariffText {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  padding-right: 6.125rem;
}

.tariff {
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}

.chargingLevelFlashContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.chargingLevelFlashIcon {
  align-self: center;
  width: 16px;
  height: 16px;
}

.StationNameText {
  font-weight: 600;
  font-size: 14px;
  min-width: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.StatusContainer {
  display: flex;
  width: 7.5rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  border-radius: 1em;
  justify-content: center;
  align-items: center;
  z-index: 0;
}
.StatusText {
  font-weight: 500;
}

.ConnectorIdContainer {
  width: 20px;
  display: flex;
  justify-content: center;
}
